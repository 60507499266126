import { V10 } from "@/interfaces/persons/v10/route";
/**
 * Routes
 */
const LOGIN_ROUTE = "/api/auth/login";
const LOGOUT_ROUTE = "/api/auth/logout";
const PROFILE_ROUTE = "/api/auth/me";
const FORGOT_ROUTE = "/api/auth/password/forgot";
const RESET_ROUTE = "/api/auth/password/reset";
const REFRESH_ROUTE = "api/auth/refresh";
const PERMISSION_ROUTE = "/api/auth/permissions";
const INITIALIZE_ROUTE = "/api/auth/init";
const ACCOUNT_ROUTE = "/api/account";
const ACCOUNT_LIST_ROUTE = "/api/account/list";
const ACCOUNT_COLORS_ROUTE = "/api/account/set_colors";
const ACCOUNT_LIST_COLORS_ROUTE = "/api/account/get_colors";
const ACCOUNT_DEFAULT_COLORS_ROUTE = "/api/account/get_default_colors";
const USER_ROUTE = "/api/users";
const EMAIL_RESEND_ROUTE = "/api/users/resend_email";
const CODE_QR_GOOGLE_ROUTE = "/api/users/get_two_factor_code";
const SET_TO_FACTOR_ROUTE = "/api/users/set_two_factor_authentication_method";
const TWOFA_ROUTE = "api/auth/two_factor_verification ";
const ADVERTISER_ROUTE = "/api/advertisers";
const ADVERTISER_CATEGORIES_ROUTE = "/api/list/advertiser_categories";
const ADVERTISER_MAKERS_ROUTE = "/api/list/advertiser_makers";
const ADVERTISER_TRADE_MARKS_ROUTE = "/api/list/advertiser_trade_marks";
const TIMEZONE_ROUTE = "/api/list/timezones";
const CURRENCY_ROUTE = "/api/list/currencies";
const CUSTOM_LIST_ROUTE = "/api/custom_lists";
const CUSTOM_LIST_EXCHANGE_ROUTE = "/api/list/custom_list_exchanges";
const CUSTOM_LIST_TYPES_ROUTE = "/api/list/custom_list_types";
const CUSTOM_LIST_CLEAR_ROUTE = "/api/custom_list_items/clear";
const LIST_ITEM_ROUTE = "/api/custom_list_items";
const MODIFIER_TYPE_ROUTE = "api/list/modifier_types";
const MODIFIER_MODULE_ROUTE = "api/list/modifier_modules";
const MODIFIER_ROUTE = "/api/modifiers";
const DELIVERY_MODIFIER_ROUTE = "/api/delivery_modifiers";
const MATCHING_ROUTE = "api/list/matching_types";
const UNIT_TIME_ROUTE = "api/list/unit_times";
const BUDGET_ROUTE = "api/list/budget_types";
const CAMPAING_KPI_ROUTE = "api/list/kpi_campaigns";
const STRATEGY_ROUTE = "api/list/strategies";
const STRATEGY_OPTIMIZATION_ROUTE = "api/list/optimization_strategies";
const CAMPAING_PACING_ROUTE = "api/list/campaign_pacing";
const CAMPAIGN_ROUTE = "api/campaigns";
const CAMPAIGN_TERMS_ROUTE = "api/campaigns/get_terms_conditions_accepted";
const MODIFIER_ASSOCIATION_ROUTE = "api/campaigns/modifier_association";
const COUNTRY_ROUTE = "api/list/countries";
const REGION_ROUTE = "api/list/regions";
const CITY_ROUTE = "api/list/cities";
const LAT_LONG_ROUTE = "api/list/custom_lists/lat_long";
const LOCATION_TYPE_ROUTE = "api/list/targeting_location_types";
const CREATIVE_SIZE_ROUTE = "api/list/creative_sizes";
const DOMAIN_LIST_ROUTE = "api/list/custom_lists/domain";
const AD_POSITION_ROUTE = "api/list/ad_positions";
const BID_SHADING_ROUTE = "api/list/bid_shadings";
const BID_STRATEGY_ROUTE = "api/list/bid_strategy";
const BID_RATE_ROUTE = "api/list/video_bid_rates";
const LINE_PACING_ROUTE = "api/list/line_pacing";
const LINE_ITEM_TYPE_ROUTE = "api/list/line_items_types";
const LINE_ITEMS_ROUTE = "api/line_items";
const CREATIVE_METHOD_ROUTE = "api/list/creatives_methods";
const CREATIVE_TYPE_ROUTE = "api/list/creative_types";
const CREATIVE_TEMPLATE_ROUTE = "api/list/creative_templates";
const CREATIVE_VENDOR_ROUTE = "api/list/creative_vendors";
const CREATIVE_RULE_ROUTE = "api/list/creative_rules";
const CREATIVE_MIME_ROUTE = "api/list/mime_types";
const CREATIVE_AUDIO_MIME_ROUTE = "api/list/audio_mime_types";
const CREATIVE_EXPANDABLE_TYPE_ROUTE = "api/list/expandable_types";
const CREATIVE_EXPANDABLE_DIRECTION_ROUTE = "api/list/expandable_directions";
const CREATIVE_VIDEO_ROUTE = "api/list/inbanner_videos";
const CREATIVE_APIS_ROUTE = "api/list/video_apis";
const CREATIVE_TAG_ROUTE = "api/creatives/check_tag";
const CREATIVE_ASSOCIATE_ROUTE = "api/creative_line_associations";
const CREATIVE_ADDONS_ROUTE = "api/creative_addons";
const CREATIVE_ASSETS_ROUTE = "api/creative_assets";
const CREATIVE_ROUTE = "api/creatives";
const CREATIVE_ASSOCIATION_ROUTE = "/api/creative-line-association";
const APP_BUNDLE_LIST_ROUTE = "api/list/custom_lists/app_bundle";
const APP_ID_ROUTE = "api/list/custom_lists/app_id";
const APP_NAME_ROUTE = "api/list/app_name";
const DEAL_ID_ROUTE = "undefined_url";
const DEAL_ID_LIST_ROUTE = "api/list/custom_lists/deal_id";
const DOMAIN_ROUTE = "api/list/custom_lists/domain";
const PLACEMENT_ROUTE = "api/list/custom_lists/placement_id";
const PUBLISER_ID_ROUTE = "undefined_url";
const PUBLISER_ID_LIST_ROUTE = "api/list/custom_lists/publisher_id";
const SITE_ROUTE = "api/list/site_id";
const SITE_LIST_ROUTE = "api/list/custom_lists/site_id";
const AUCTION_TYPE_ROUTE = "api/list/auction_types";
const INVENTORY_SOURCE_ROUTE = "api/list/inventory_sources";
const INTERSTITIAL_FLAGS = "/api/list/interstitial_flags";
const INTESTITIAL_TYPES = "api/list/interstitial_types";
const INVENTORY_VIDEO_APIS = "api/list/video_apis";
const TARGETING_ROUTE = "api/targeting_expressions";
const TARGETING_PREDICATE_ROUTE = "api/list/targeting_predicates";
const TARGETING_KEY_ROUTE = "api/targeting_keys";
const TARGETING_LOCATION_TYPE_ROUTE = "api/list/targeting_location_types";
const TARGETING_MODULE_ROUTE = "api/list/targeting_modules";
const SUMMARY_TARGETING_EXPRESSIONS_ROUTE = "/api/summary_targeting_expressions";
const ENVIROMENT_TYPE_ROUTE = "api/list/environment_types";
const ENVIROMENT_TPFRAME_ROUTE = "api/list/topframes";
const ENVIROMENT_VIDEO_ROUTE = "api/list/video_apis";
const ENVIROMENT_REWARDED_ROUTE = "api/list/rewarded";
const PLATFORM_BANDWITDH = "api/list/bandwidths";
const PLATFORM_BROWSER_ROUTE = "api/list/browsers";
const PLATFORM_BROWSER_VERSION_ROUTE = "api/list/browser_versions";
const PLATFORM_CARRIER_ROUTE = "api/list/carriers";
const PLATFORM_DEVICE_TYPE_ROUTE = "api/list/devices";
const PLATFORM_DEVICE_MAKE_ROUTE = "api/list/device_makes";
const PLATFORM_DEVICE_MODEL_ROUTE = "api/list/device_models";
const PLATFORM_DEVICE_SCREEN_SIZE_ROUTE = "api/list/device_screen_sizes";
const PLATFORM_OPERATING_SYSTEM_ROUTE = "api/list/operating_systems";
const PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE = "api/list/operating_system_versions";
const CONTENT_CATEGORY_ROUTE = "api/list/content_categories";
const CONTENT_LENGUAGE_ROUTE = "api/list/languages";
const CONTENT_RATINGS_ROUTE = "api/list/content_ratings";
const NATIVE_NATIVE_LAYOUT_ROUTE = "api/list/native_layouts";
const VIDEO_COMPANION_REQUIRED = "api/list/companion_support";
const VIDEO_PLAYBACK_METHOD = "api/list/video_playback_methods";
const VIDEO_START_DELAY = "api/list/video_start_delays";
const VIDEO_PLAYER_SIZE = "api/list/video_player_sizes";
const VIDEO_PLACEMENT_TYPE = "api/list/video_placement_types";
const TYPES_FRECUENCY_CAPS_ROUTE = "/api/list/frequency_cap_type";
const SET_COLOR_ROUTE = "/api/account/design_settings";

const SEGMENTS_ROUTE = "/api/segments";
const SEGMENTS_CATEGORY_ROUTE = "api/segment_categories";
const SEGMENT_PROVIDERS_ROUTER = "api/list/segment_providers";
const SEGMENTS_CATEGORY_LIST_ROUTE = "api/list/segment_categories";
const SEGMENTS_THIRDPARTY_ROUTE = "api/third_party/segments";
const SEGMENTS_CATEGORY_THIRDPARTY_ROUTE = "api/third_party/categories";
const SEGMENT_TAG_ROUTE = "api/third_party/segment_tag";
const SEGMENT_TREE_VIEW_ROUTE = "/api/segments/view/tree";
const SEGMENT_GENERATED_TAG_ROUTE = "api/segment_tag";
const SEGMENT_THIRD_TREE = "api/third_party/category_segment_tree";
const SEGMENT_SEARCH = "/api/segments/search";

const MODELS_CREATE_ROUTE = "/api/bidmodels";
const MODELS_PAGINATED_ROUTE = "/api/bidmodels";
const MODELS_METRICS_LIST_ROUTE = "/api/bidmodels/get_metrics";
const BIDMODELS_LIST_ROUTE = "/api/bidmodels";

const LOOKALIKE_CREATE_ROUTE = "/api/lookalike/spawn_seed";
const LOOKALIKE_ACTIVATE_ROUTE = "/api/lookalike/activate";
const LOOKALIKE_PAGINATED_ROUTE = "/api/lookalikes";

//EVENTS
const EVENT_ROUTE = "/api/events";
const EVENT_ATTRIBUTIONS_ROUTE = "/api/list/event_attributions";
const EVENT_TYPES_ROUTE = "/api/list/event_types";
const EVENT_TAG_TYPES_ROUTE = "/api/list/event_tag_type";
const EVENT_GENERATE_TAG_ROUTE = "/api/events/event_tag";

//EVENTS ASSOCIATE
const EVENT_ASSOCIATIONS_LINE_ROUTE = "/api/events/line_item_association";
const EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE = "/api/events/campaign_association";

const LINE_ASSOCIATIONS_EVENT_ROUTE = "/api/line_items/event_association";
const CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE = "/api/campaigns/event_association";

const LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE = "api/line_items/modifier_association";

const LINE_ITEM_BID_WIN_ROUTE = "/api/line_items/daily_bid_win";
const CREATIVE_LINE_ASSOCIATIONS_ROUTE = "/api/creative_line_associations";

const REPORT_ROUTE = "api/reports";
const REPORT_TYPE_ROUTE = "api/list/report_types";
const REPORT_DATA_RANGE_ROUTE = "api/list/report_data_range";
const REPORT_ADVERTISER_ROUTE = "api/list/report_advertisers";
const REPORT_CAMPAIGN_ROUTE = "api/list/report_campaigns";
const REPORT_LINE_ROUTE = "api/list/report_line_items";
const REPORT_CREATIVE_ROUTE = "api/list/report_creatives";
const REPORT_FORMAT_TYPE_ROUTE = "api/list/report_format_types";
const REPORT_FIELD_ROUTE = "api/list/report_fields";
const REPORT_GET_DIMENSIONS_ROUTE = "api/reports/get_dimensions/";
const REPORT_ALL_TYPE_ROUTE = "api/reports/get_types";
const REPORT_GET_METRICS_ROUTE = "api/reports/get_metrics/";
const REPORT_GET_SCHEDULER_TYPES_ROUTE = "api/reports_schedule/get_types";
const REPORT_SCHEDULER_ROUTE = "api/reports_schedule";
const REPORT_SCHEDULER_BULK_ROUTE = "api/reports_schedule/bulk";
const REPORT_COUNTRY_ROUTE = "/api/list/on_demand_countries";
const REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE = "/api/on_demand_report/store_attribution";
const REPORT_DEMAND_GEO_ROUTE = "/api/on_demand_report/geo_report";
const REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE = "/api/on_demand_report/campaign_insight";
const REPORT_DEMAND_UNIQUE_DEVICES_ROUTE = "/api/on_demand_report/unique_devices";
const REPORT_DEMAND_LIST_ROUTE = "/api/list/custom_lists/lat_long";
const REPORT_ON_DEMAND_ROUTE = "/api/on_demand_report";
const REPORT_COUNTRY_CAMPAIGN_ROUTE = "/api/list/countries";
const REPORT_DOWNLOAD_ON_DEMAND_ROUTE = "/api/on_demand_report/download/";

const PERSON_COUNTRY_ROUTE = "c";
const PERSON_DPTO_ROUTE = "api/persons/departments";
const PERSON_CITY_ROUTE = "api/persons/cities";
const PERSON_CATEGORY_ROUTE = "api/persons/categories";
const PERSON_SUBCATEGORY_ROUTE = "api/persons/subcategories";
const PERSON_BRAND_ROUTE = "api/persons/brands";
const PERSON_NAME_ROUTE = "api/persons/names";
const PERSON_POIS_GRAPHIC = "api/persons/pois_graphics";
const PERSON_REFRESH_AUDIENE = "/api/persons/audience_graphics";
const PERSON_POIS_MAP = "/api/persons/pois_map";
const PERSON_AUDIENCE_MAP = "/api/persons/audience_map";
const PERSON_EXPORT_AUDIENCE = "/api/persons/create_segment";
const PERSON_PRIVATE_POIS_ROUTE = "/api/personas/private_pois";
const PERSON_GET_PRIVATE_POIS_ROUTE = "/api/personas/private_pois";
const PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE = "/api/personas/countries";
const PERSON_GET_DEPARTMENTS_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/departments";
const PERSON_GET_CITIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/cities";
const PERSON_GET_CATEGORIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/categories";
const PERSON_GET_SUBCATEGORIES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/subcategories";
const PERSON_GET_BRANDS_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/brands";
const PERSON_GET_NAMES_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/names";
const PERSONA_CREATE_LAYER_PRIVATE_POIS_ROUTE = "/api/persons/private_pois/save_layer";
const PERSON_EXPORT_TO_LIST_ROUTE = "/api/persons/export_lat_lon_list";

// POIS neighborhood
const PERSON_NEIGHBORHOOD_ROUTE = "/api/persons/neighborhood";
const PERSON_PRIVATE_NEIGHBORHOOD_ROUTE = "/api/persons/private_pois/neighborhood";

// AUDIENCE

const AUDIENCE_DATES_ROUTE = "/api/persons/users_by_date";
const AUDIENCE_DISTANCES_ROUTE = "/api/persons/users_by_distance";
const AUDIENCE_WEEK_DAY_ROUTE = "/api/persons/users_by_week_day";
const AUDIENCE_HOUR_DAY_ROUTE = "/api/persons/users_by_hour_day";

// AUDIENCE pois
const AUDIENCE_CATEGORIES_ROUTE = "/api/persons/users_by_category";
const AUDIENCE_SUBCATEGORIES_ROUTE = "/api/persons/users_by_subcategory";
const AUDIENCE_DEPARTMENTS_ROUTE = "/api/persons/users_by_dpto";
const AUDIENCE_CITIES_ROUTE = "/api/persons/users_by_poi_city";
const AUDIENCE_NEIGHBORHOOD_ROUTE = "/api/persons/users_by_poi_neighborhood";
const AUDIENCE_BRANDS_ROUTE = "/api/persons/users_by_marca";
const AUDIENCE_NAMES_ROUTE = "/api/persons/users_by_poi_name";

// AUDIENCE demographics
const AUDIENCE_GENDERS_ROUTE = "/api/persons/users_by_gender";
const AUDIENCE_AGES_ROUTE = "/api/persons/users_by_age";
const AUDIENCE_RESIDENCE_CITIES_ROUTE = "/api/persons/users_by_ciudad_residencia";
const AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE = "/api/persons/users_by_barrio_residencia";
const AUDIENCE_SOCIOECONOMICS_ROUTE = "/api/persons/users_by_niv_socio";
const AUDIENCE_SOCIOECONOMICS_ROUTE_BR = "/api/persons/users_by_estrato_br";

// AUDIENCE online_behaviour
const AUDIENCE_IDENTIFIER_TYPE_ROUTE = "/api/persons/users_by_type";
const AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE = "/api/persons/users_by_iab";
const AUDIENCE_CAMPAIGN_INTERESTS_ROUTE = "/api/persons/users_by_intereses";
const AUDIENCE_APP_VISITED_ROUTE = "/api/persons/users_by_app_name";
const AUDIENCE_SITES_VISITED_ROUTE = "/api/persons/users_by_domain";
const AUDIENCE_CITY_SEEN_ROUTE = "/api/persons/users_by_city_seen";

// AUDIENCE device
const AUDIENCE_BROWSER_ROUTE = "/api/persons/users_by_platform_browser";
const AUDIENCE_LANGUAGE_ROUTE = "/api/persons/users_by_device_language";
const AUDIENCE_MAKE_ROUTE = "/api/persons/users_by_device_make";
const AUDIENCE_DEVICE_TYPE_ROUTE = "/api/persons/users_by_platform_device_type";
const AUDIENCE_OPERATING_SYSTEM_ROUTE = "/api/persons/users_by_platform_os";
const AUDIENCE_OPERATOR_ROUTE = "/api/persons/users_by_carrier";

// GENERATE AUDIENCE
const PERSON_GENERATED_AUDIENCE = "api/persons/generate_audience";

// GENERATE DEMO
const PERSON_GENERATE_DEMO = "api/persons/generate_demo";
// GENERATE DEMO
const PERSON_FILTER_AUDIENCE_ROUTE = "api/persons/filter_audience";

// GENDER_AGE
const PERSON_GENDER_AGE = "api/persons/users_by_pois_gender_age";
const PERSON_GENDER_AGE_DOWNLOAD = "api/persons/users_by_pois_gender_age_download";

// HEAD_MAP
const PERSON_HOUR_DAY_WEEK = "api/persons/users_by_hour_day_week";

const AUDIENCE_GRAPHICS_CATEGORY_ROUTE = "/api/persons/category_graph";
const AUDIENCE_GRAPHICS_DAY_HOUR_ROUTE = "/api/persons/day_hour_graph";
const AUDIENCE_GRAPHICS_DATE_ROUTE = "/api/persons/date_graph";
const AUDIENCE_GRAPHICS_TOTAL_CATEGORIES_ROUTE = "/api/persons/total_categories_graph";
const AUDIENCE_GRAPHICS_TOTAL_SUMMARY_ROUTE = "/api/persons/total_summary_point_graph";

const MODULE_MODIFIER = "/api/modifiers_options";

const CREATIVE_BULK_ROUTE = "/api/creative_bulk";

const REPORT_LIST_ROUTE = "/api/list/report_list";
const LOOKER_ENDPOINT = "/api/reports/generate_looker_url";
const REPORT_DIMENSION_AND_METRIC_SP_ROUTE =
	"/api/reports/get_metrics_and_dimensions_sp";
const REPORT_SP_ROUTE = "/api/reports/get_report_sp";
const REPORT_FILTERS_SP = "/api/reports/get_filters_sp";
const REPORT_DATA_RANGE_SP_ROUTE = "/api/reports/get_data_range_sp";
const REPORT_FORMAT_TYPE_SP_ROUTE = "/api/reports/get_types_sp";
const REPORT_ACCOUNT_SP_ROUTE = "/api/reports/get_accounts_sp";

const CLONE_CAMPAIGN_ROUTE = "/api/campaigns/clone";
const CLONE_LINE_ITEM_ROUTE = "/api/line_items/clone";
const CLONE_CREATIVE_ROUTE = "/api/creatives/clone";

const CONTINENT_ROUTE = "/api/list/continents";

const ACTIVITY_LOG_CAMPAIGN_ROUTE = "/api/campaigns/audit";
const ACTIVITY_LOG_ADVERTISER_ROUTE = "/api/advertisers/audit";
const ACTIVITY_LOG_LINE_ITEM_ROUTE = "/api/line_items/audit";
const ACTIVITY_LOG_CREATIVE_ROUTE = "/api/creatives/audit";

const EXT_CAMPAIGN_ROUTE = "/api/ext_campaigns";
const EXT_CAMPAIGN_TYPES_ROUTE = "/api/list/ext_campaign_types";
const EXT_CAMPAIGN_PROVIDERS_ROUTE = "/api/list/ext_campaign_providers";
const EXT_BUY_MODELS_ROUTE = "/api/list/buy_models";
const EXT_CAMPAIGN_MMP_ROUTE = "/api/list/ext_campaign_mmp";
const EXT_CAMPAIGN_OWNERS_ROUTE = "/api/list/ext_campaign_owners";
const EXT_CAMPAIGN_STATUS_ROUTE = "/api/list/ext_campaign_status";
const EXT_CAMPAIGN_NOTES_ROUTE = "/api/ext_campaign_notes";
const EXT_CAMPAIGN_CREATIVE_ROUTE = "/api/ext_creatives";
const EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE = "/api/ext_creatives_display";
const EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE = "/api/ext_creatives_video";
const EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE = "/api/ext_creatives_native";
const EXT_CAMPAIGN_SEND_EMAIL_ROUTE = "api/ext_campaign_send_email";
const EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE = "/api/list/ext_creative_size";
const EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE = "/api/list/ext_creative_aspect_ratio";
const EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE = "/api/list/ext_creative_type";
const NOTIFICATION_ROUTE = "/api/notifications";
const NOTIFICATION_SETTINGS_ROUTE = "/api/notifications/settings";
const NOTIFICATION_SET_ACTIVE = "/api/notifications/setActive";
const NOTIFICATION_DISMISS = "/api/notifications/mark_read";

const TRANSLATION_LANGUAGES = "/api/list/translation_languages";

const V10 = {
  PERSONS: {
    TAB_FILTERS: {
      COUNTRIES: "/api/personas/countries",
      STRATEGIES: "/api/personas/audience_strategy_types",
      USE_CASES: "/api/personas/audience_use_case_types",
      GEO: {
        STATES: "/api/personas/estados",
        CITIES: "/api/personas/municipios",
        NEIGHBORHOODS: "/api/personas/barrios",
      },
      POIS: {
        CATEGORIES: "/api/personas/categorias",
        SUBCATEGORIES: "/api/personas/subcategorias",
        BRANDS: "/api/personas/marcas",
        NAMES: "/api/personas/nombres",
      },
      PRIVATES: "/api/personas/private_pois",
      POST_PRIVATES: "/api/personas/private_pois_menu",
      OOH: {
        CATEGORIES: "/api/personas/ooh_categorias",
        SUBCATEGORIES: "/api/personas/ooh_subcategorias",
        BRANDS: "/api/personas/ooh_marcas",
        NAMES: "/api/personas/ooh_nombres",
      },
      POS: {
        CHIPPER_STATES: "/api/personas/chipper_estados",
        CHIPPER_CITIES: "/api/personas/chipper_municipios",
        CHIPPER_NEIGHBORHOODS: "/api/personas/chipper_barrios",
        CHIPPER_MACRO_CATEGORIES: "/api/personas/chipper_macro",
        CHIPPER_CATEGORIES: "/api/personas/chipper_categories",
        CHIPPER_COMPANIES: "/api/personas/chipper_company",
        CHIPPER_BRANDS: "/api/personas/chipper_brand",
        CHIPPER_NAMES_SKU: "/api/personas/chipper_name_sku",
        CHIPPER_STORES_TYPE: "/api/personas/chipper_store_type",
      },
    },
    ANALIZE: {
      POIS: "/api/personas/analyze_pois",
      GEO: "/api/personas/analyze_geo",
      POS: "/api/personas/analyze_chipper",
    },
    GRAPHIC: {
      GEO: {
        STATES: "/api/personas/analyze_geo/estados",
        CITIES: "/api/personas/analyze_geo/municipios",
        NEIGHBORHOODS: "/api/personas/analyze_geo/barrios",
      },
      POIS: {
        CATEGORIES: "/api/personas/analyze_pois/categorias",
        SUBCATEGORIES: "/api/personas/analyze_pois/subcategorias",
        BRANDS: "/api/personas/analyze_pois/marcas",
        STATES: "/api/personas/analyze_pois/estados",
        CITIES: "/api/personas/analyze_pois/municipios",
        NEIGHBORHOODS: "/api/personas/analyze_pois/barrios",
      },
    },
    CALCULATE: {
      POIS: "/api/personas/calculate_reach/pois",
      GEO: "/api/personas/calculate_reach/geo",
      POS: "/api/personas/calculate_reach/chipper",
      STORE_ATTRIBUTION: "/api/personas/calculate_reach/store_atr",
    },
    TOTAL_POIS: "/api/personas/total_pois",
    TOTAL_POIS_TYPE: "/api/personas/analyze_pois/types",
    SAVE_POIS: "/api/personas/private_pois/save_layer",
    EXPORT_LAT_LON_LIST: "/api/personas/export_lat_lon_list",
    LIST_TO_PRIVATE_POIS: "/api/personas/private_pois/list_to_private_poi",
    STORE_ATTRIBUTION: "/api/personas/store_atr/campaigns",
    ACTIVATE_OOH: "/api/personas/sent_notification_to_ooh",
    GET_TABLE_NAME: "/api/personas/get_table_name",
  },
  AUDIENCE: {
    POIS: {
      AUDIENCE: "/api/personas/pois_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/pois_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/pois_bid_request_audience",
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      TOTAL_REACH: "/api/personas/audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/audience/proximity_to_poi",
      GENDER: "/api/personas/audience/gender",
      AGE: "/api/personas/audience/age",
      NIV_SOCIO_BR: "/api/personas/audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/audience/sub_category_poi",
      MARCA_POI: "/api/personas/audience/marca_poi",
      DPTO_POI: "/api/personas/audience/dpto_poi",
      CITY_POI: "/api/personas/audience/city_poi",
      BARRIO_POI: "/api/personas/audience/barrio_poi",
      DATE_OF_WEEK: "/api/personas/audience/date_of_week",
      TIME_OF_DAY: "/api/personas/audience/time_of_day",
      DATE: "/api/personas/audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/audience/recidence_barrio",
      IAB: "/api/personas/audience/iab",
      INTEREST: "/api/personas/audience/interest",
      SITES: "/api/personas/audience/domain",
      APP_BUNDLE: "/api/personas/audience/app_bundle",
      APP_NAME: "/api/personas/audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/audience/device_language",
      CITY_CONNECTION: "/api/personas/audience/city",
      CARRIER: "/api/personas/audience/carrier",
      DEVICE_TYPE: "/api/personas/audience/device_type",
      MAKE: "/api/personas/audience/device_make",
      BROWSER: "/api/personas/audience/browser",
      OS: "/api/personas/audience/os",
      TABLES: "/api/personas/audience/get_reports",
      // METRICS_BY_BRAND_AND_POI: "/api/personas/audience/metrics_by_brand_and_poi",
      // ATTRIBUTIONS: "/api/personas/audience/attributions",
      // RICH_ATTRIBUTIONS: "/api/personas/audience/rich_attributions",
      // RICH_ATTRIBUTIONS_DAILY: "/api/personas/analyze/rich_attributions"
    },
    GEO: {
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/geo_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/geo_bid_request_audience",
      TOTAL_REACH: "/api/personas/geo_audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/geo_audience/proximity_to_poi",
      GENDER: "/api/personas/geo_audience/gender",
      AGE: "/api/personas/geo_audience/age",
      NIV_SOCIO_BR: "/api/personas/geo_audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/geo_audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/geo_audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/geo_audience/sub_category_poi",
      MARCA_POI: "/api/personas/geo_audience/marca_poi",
      DPTO_POI: "/api/personas/geo_audience/metrics_dpto",
      CITY_POI: "/api/personas/geo_audience/metrics_city",
      BARRIO_POI: "/api/personas/geo_audience/metrics_barrio",
      DATE_OF_WEEK: "/api/personas/geo_audience/date_of_week",
      TIME_OF_DAY: "/api/personas/geo_audience/time_of_day",
      DATE: "/api/personas/geo_audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/geo_audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/geo_audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/geo_audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/geo_audience/recidence_barrio",
      IAB: "/api/personas/geo_audience/iab",
      INTEREST: "/api/personas/geo_audience/interest",
      SITES: "/api/personas/geo_audience/domain",
      APP_BUNDLE: "/api/personas/geo_audience/app_bundle",
      APP_NAME: "/api/personas/geo_audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/geo_audience/device_language",
      CITY_CONNECTION: "/api/personas/geo_audience/city",
      CARRIER: "/api/personas/geo_audience/carrier",
      DEVICE_TYPE: "/api/personas/geo_audience/device_type",
      MAKE: "/api/personas/geo_audience/device_make",
      BROWSER: "/api/personas/geo_audience/browser",
      OS: "/api/personas/geo_audience/os",
      TABLES: "/api/personas/geo_audience/get_reports",
      // METRICS_BY_BRAND_AND_POI: "/api/personas/audience/metrics_by_brand_and_poi",
      // ATTRIBUTIONS: "/api/personas/audience/attributions",
      // RICH_ATTRIBUTIONS: "/api/personas/audience/rich_attributions",
      // RICH_ATTRIBUTIONS_DAILY: "/api/personas/analyze/rich_attributions"
    },
    POS: {
      ANALIZE_AUDIENCE: "/api/personas/analyze_audience",
      DEMO_HOME_AUDIENCE: "/api/personas/chiper_demo_home_audience",
      BID_REQUEST_AUDIENCE: "/api/personas/chiper_bid_request_audience",
      TOTAL_REACH: "/api/personas/geo_audience/total_reach",
      PROXIMITY_TO_POI: "/api/personas/geo_audience/proximity_to_poi",
      GENDER: "/api/personas/geo_audience/gender",
      AGE: "/api/personas/geo_audience/age",
      NIV_SOCIO_BR: "/api/personas/geo_audience/niv_socio_br",
      NIV_SOCIO_ALL_EXP_BR: "/api/personas/geo_audience/niv_socio_all_exp_br",
      CATEGORY_POI: "/api/personas/geo_audience/category_poi",
      SUB_CATEGORY_POI: "/api/personas/geo_audience/sub_category_poi",
      MARCA_POI: "/api/personas/geo_audience/marca_poi",
      DPTO_POI: "/api/personas/geo_audience/metrics_dpto",
      CITY_POI: "/api/personas/geo_audience/metrics_city",
      BARRIO_POI: "/api/personas/geo_audience/metrics_barrio",
      DATE_OF_WEEK: "/api/personas/geo_audience/date_of_week",
      TIME_OF_DAY: "/api/personas/geo_audience/time_of_day",
      DATE: "/api/personas/geo_audience/date",
      TIME_OF_DAY_OF_WEEK: "/api/personas/geo_audience/time_of_day_of_week",
      RESIDENCE_DPTO: "/api/personas/geo_audience/recidence_dpto",
      RESIDENCE_CITY: "/api/personas/geo_audience/recidence_city",
      RESIDENCE_BARRIO: "/api/personas/geo_audience/recidence_barrio",
      IAB: "/api/personas/geo_audience/iab",
      INTEREST: "/api/personas/geo_audience/interest",
      SITES: "/api/personas/geo_audience/domain",
      APP_BUNDLE: "/api/personas/geo_audience/app_bundle",
      APP_NAME: "/api/personas/geo_audience/app_name",
      CONTENT_LANGUAGE: "/api/personas/geo_audience/device_language",
      CITY_CONNECTION: "/api/personas/geo_audience/city",
      CARRIER: "/api/personas/geo_audience/carrier",
      DEVICE_TYPE: "/api/personas/geo_audience/device_type",
      MAKE: "/api/personas/geo_audience/device_make",
      BROWSER: "/api/personas/geo_audience/browser",
      OS: "/api/personas/geo_audience/os",
      TABLES: "/api/personas/geo_audience/get_reports",
    },
    FILTER_GRAPHIC: "/api/personas/filter_audience",
    GET_FILTER: "/api/personas/audience/get_filter",
    DOWNLOAD_METRICS: "/api/personas/download_demographics_by_poi",
    DOWNLOAD_ATTRIBUTIONS: "/api/personas/download_matrix_poi",
    DOWNLOAD_RICH_ATTRIBUTIONS: "/api/personas/download_visits_by_distance_by_poi",
    DOWNLOAD_RICH_ATTRIBUTIONS_DAILY: "/api/personas/download_rich_attribution",
    CREATE_SEGMENT: "/api/personas/create_segment",
    AUDIENCE_TYPE: "/api/personas/audience_type",
    CAN_FILTER_READY: "/api/personas/filter_audience_ready",
  },
} as V10;

module.exports = {
  V10,
  PERSON: {
    PERSON_COUNTRY_ROUTE,
    PERSON_DPTO_ROUTE,
    PERSON_CITY_ROUTE,
    PERSON_CATEGORY_ROUTE,
    PERSON_SUBCATEGORY_ROUTE,
    PERSON_BRAND_ROUTE,
    PERSON_NAME_ROUTE,
    PERSON_POIS_GRAPHIC,
    PERSON_REFRESH_AUDIENE,
    PERSON_POIS_MAP,
    PERSON_AUDIENCE_MAP,
    PERSON_EXPORT_AUDIENCE,
    PERSON_PRIVATE_POIS_ROUTE,
    PERSON_GET_PRIVATE_POIS_ROUTE,
    PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_DEPARTMENTS_PRIVATE_POIS_ROUTE,
    PERSON_GET_CITIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_CATEGORIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_SUBCATEGORIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_BRANDS_PRIVATE_POIS_ROUTE,
    PERSON_GET_NAMES_PRIVATE_POIS_ROUTE,
    PERSONA_CREATE_LAYER_PRIVATE_POIS_ROUTE,
    PERSON_EXPORT_TO_LIST_ROUTE,
    GENERATE: {
      PERSON_GENERATED_AUDIENCE,
      PERSON_GENERATE_DEMO,
      PERSON_FILTER_AUDIENCE_ROUTE,
    },
    POIS: {
      PERSON_NEIGHBORHOOD_ROUTE,
      PERSON_PRIVATE_NEIGHBORHOOD_ROUTE,
      PERSON_POIS_GRAPHIC,
    },
    AUDIENCE: {
      AUDIENCE_DATES_ROUTE,
      AUDIENCE_DISTANCES_ROUTE,
      AUDIENCE_WEEK_DAY_ROUTE,
      AUDIENCE_HOUR_DAY_ROUTE,

      // AUDIENCE pois
      AUDIENCE_CATEGORIES_ROUTE,
      AUDIENCE_SUBCATEGORIES_ROUTE,
      AUDIENCE_DEPARTMENTS_ROUTE,
      AUDIENCE_CITIES_ROUTE,
      AUDIENCE_NEIGHBORHOOD_ROUTE,
      AUDIENCE_BRANDS_ROUTE,
      AUDIENCE_NAMES_ROUTE,

      // AUDIENCE demographics
      AUDIENCE_GENDERS_ROUTE,
      AUDIENCE_AGES_ROUTE,
      AUDIENCE_RESIDENCE_CITIES_ROUTE,
      AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE,
      AUDIENCE_SOCIOECONOMICS_ROUTE,
      AUDIENCE_SOCIOECONOMICS_ROUTE_BR,

      // AUDIENCE online_behaviour
      AUDIENCE_IDENTIFIER_TYPE_ROUTE,
      AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE,
      AUDIENCE_CAMPAIGN_INTERESTS_ROUTE,
      AUDIENCE_APP_VISITED_ROUTE,
      AUDIENCE_SITES_VISITED_ROUTE,
      AUDIENCE_CITY_SEEN_ROUTE,

      // AUDIENCE device
      AUDIENCE_BROWSER_ROUTE,
      AUDIENCE_LANGUAGE_ROUTE,
      AUDIENCE_MAKE_ROUTE,
      AUDIENCE_DEVICE_TYPE_ROUTE,
      AUDIENCE_OPERATING_SYSTEM_ROUTE,
      AUDIENCE_OPERATOR_ROUTE,

      // GENDER AGE
      PERSON_GENDER_AGE,
      PERSON_GENDER_AGE_DOWNLOAD,

      // HEAT_MAP
      PERSON_HOUR_DAY_WEEK,

      // AUDIENCE_GRAPHICS_CATEGORY_ROUTE,
      // AUDIENCE_GRAPHICS_DAY_HOUR_ROUTE,
      // AUDIENCE_GRAPHICS_DATE_ROUTE,
      // AUDIENCE_GRAPHICS_TOTAL_CATEGORIES_ROUTE,
      // AUDIENCE_GRAPHICS_TOTAL_SUMMARY_ROUTE,
    },
  },
  REPORT: {
    REPORT_ROUTE,
    REPORT_TYPE_ROUTE,
    REPORT_DATA_RANGE_ROUTE,
    REPORT_ADVERTISER_ROUTE,
    REPORT_CAMPAIGN_ROUTE,
    REPORT_LINE_ROUTE,
    REPORT_CREATIVE_ROUTE,
    REPORT_FORMAT_TYPE_ROUTE,
    REPORT_FIELD_ROUTE,
    REPORT_LIST_ROUTE,
    LOOKER_ENDPOINT,
    REPORT_GET_DIMENSIONS_ROUTE,
    REPORT_ALL_TYPE_ROUTE,
    REPORT_GET_METRICS_ROUTE,
    REPORT_GET_SCHEDULER_TYPES_ROUTE,
    REPORT_SCHEDULER_ROUTE,
    REPORT_COUNTRY_ROUTE,
    REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE,
    REPORT_DEMAND_GEO_ROUTE,
    REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE,
    REPORT_DEMAND_UNIQUE_DEVICES_ROUTE,
    REPORT_DEMAND_LIST_ROUTE,
    REPORT_ON_DEMAND_ROUTE,
    REPORT_COUNTRY_CAMPAIGN_ROUTE,
    REPORT_DOWNLOAD_ON_DEMAND_ROUTE,
    REPORT_SCHEDULER_BULK_ROUTE,
    REPORT_DIMENSION_AND_METRIC_SP_ROUTE,
    REPORT_SP_ROUTE,
    REPORT_FILTERS_SP,
    REPORT_DATA_RANGE_SP_ROUTE,
    REPORT_FORMAT_TYPE_SP_ROUTE,
    REPORT_ACCOUNT_SP_ROUTE,
  },
  ADVERTISER: {
    ADVERTISER_ROUTE,
    ADVERTISER_CATEGORIES_ROUTE,
    ADVERTISER_MAKERS_ROUTE,
  },
  AD_SIZE: {
    CREATIVE_SIZE_ROUTE,
  },
  APP_SITE: {
    APP_BUNDLE_LIST_ROUTE,
    APP_ID_ROUTE,
    APP_NAME_ROUTE,
    DEAL_ID_ROUTE,
    DEAL_ID_LIST_ROUTE,
    DOMAIN_ROUTE,
    PLACEMENT_ROUTE,
    PUBLISER_ID_ROUTE,
    PUBLISER_ID_LIST_ROUTE,
    SITE_ROUTE,
    SITE_LIST_ROUTE,
  },
  AUTH: {
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    PROFILE_ROUTE,
    FORGOT_ROUTE,
    RESET_ROUTE,
    REFRESH_ROUTE,
    PERMISSION_ROUTE,
    INITIALIZE_ROUTE,
    ACCOUNT_ROUTE,
    ACCOUNT_LIST_ROUTE,
    CODE_QR_GOOGLE_ROUTE,
    TWOFA_ROUTE,
    SET_TO_FACTOR_ROUTE,
  },
  BID: {
    BID_SHADING_ROUTE,
    BID_STRATEGY_ROUTE,
    BID_RATE_ROUTE,
  },
  BUDGET: {
    BUDGET_ROUTE,
  },
  CAMPAIGN: {
    CAMPAIGN_ROUTE,
    MODIFIER_ASSOCIATION_ROUTE,
    CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE,
    CAMPAIGN_TERMS_ROUTE,
  },
  CONTENT: {
    CONTENT_CATEGORY_ROUTE,
    CONTENT_LENGUAGE_ROUTE,
    CONTENT_RATINGS_ROUTE,
  },
  CREATIVES: {
    CREATIVE_ROUTE,
    CREATIVE_METHOD_ROUTE,
    CREATIVE_TYPE_ROUTE,
    CREATIVE_TEMPLATE_ROUTE,
    CREATIVE_VENDOR_ROUTE,
    CREATIVE_RULE_ROUTE,
    CREATIVE_TAG_ROUTE,
    CREATIVE_MIME_ROUTE,
    CREATIVE_AUDIO_MIME_ROUTE,
    CREATIVE_EXPANDABLE_TYPE_ROUTE,
    CREATIVE_EXPANDABLE_DIRECTION_ROUTE,
    CREATIVE_VIDEO_ROUTE,
    CREATIVE_APIS_ROUTE,
    CREATIVE_ASSOCIATE_ROUTE,
    CREATIVE_ADDONS_ROUTE,
    CREATIVE_ASSETS_ROUTE,
    CREATIVE_BULK_ROUTE,
    CREATIVE_ASSOCIATION_ROUTE,
    CREATIVE_LINE_ASSOCIATIONS_ROUTE,
  },
  CURRENCY: {
    CURRENCY_ROUTE,
  },
  CUSTOM_LIST: {
    CUSTOM_LIST_ROUTE,
    CUSTOM_LIST_EXCHANGE_ROUTE,
    CUSTOM_LIST_TYPES_ROUTE,
    CUSTOM_LIST_CLEAR_ROUTE,
    LIST_ITEM_ROUTE,
    TYPES_FRECUENCY_CAPS_ROUTE,
  },
  DOMAIN: {
    DOMAIN_LIST_ROUTE,
  },
  ENVIROMENT: {
    ENVIROMENT_TYPE_ROUTE,
    ENVIROMENT_TPFRAME_ROUTE,
    ENVIROMENT_VIDEO_ROUTE,
    ENVIROMENT_REWARDED_ROUTE,
  },
  EXCHANGE: {
    AUCTION_TYPE_ROUTE,
    INVENTORY_SOURCE_ROUTE,
  },
  GEO: {
    COUNTRY_ROUTE,
    REGION_ROUTE,
    CITY_ROUTE,
    LAT_LONG_ROUTE,
    LOCATION_TYPE_ROUTE,
  },
  INVENTORY: {
    AD_POSITION_ROUTE,
    AUCTION_TYPE_ROUTE,
    ENVIROMENT_TYPE_ROUTE,
    INVENTORY_SOURCE_ROUTE,
    INTERSTITIAL_FLAGS,
    INTESTITIAL_TYPES,
    INVENTORY_VIDEO_APIS,
    DEAL_ID_LIST_ROUTE,
    PLACEMENT_ROUTE,
    PUBLISER_ID_LIST_ROUTE,
    SITE_LIST_ROUTE,
  },
  KPI: {
    CAMPAING_KPI_ROUTE,
  },
  LINE_ITEMS: {
    LINE_ITEM_TYPE_ROUTE,
    LINE_ITEMS_ROUTE,
    LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE,
    CREATIVE_ASSOCIATION_ROUTE,
    LINE_ITEM_BID_WIN_ROUTE,
    CREATIVE_LINE_ASSOCIATIONS_ROUTE,
    LINE_ASSOCIATIONS_EVENT_ROUTE,
  },
  MATCHING: {
    MATCHING_ROUTE,
  },
  MODIFIERS: {
    MODIFIER_TYPE_ROUTE,
    MODIFIER_MODULE_ROUTE,
    MODIFIER_ROUTE,
    MODULE_MODIFIER,
    DELIVERY_MODIFIER_ROUTE,
  },
  MODELS: {
    MODELS_CREATE_ROUTE,
    MODELS_PAGINATED_ROUTE,
    MODELS_METRICS_LIST_ROUTE,
    BIDMODELS_LIST_ROUTE,
  },
  LOOKALIKE: {
    LOOKALIKE_CREATE_ROUTE,
    LOOKALIKE_PAGINATED_ROUTE,
    LOOKALIKE_ACTIVATE_ROUTE,
  },
  PACING: {
    CAMPAING_PACING_ROUTE,
    LINE_PACING_ROUTE,
  },
  PLATFORM: {
    PLATFORM_BROWSER_ROUTE,
    PLATFORM_BROWSER_VERSION_ROUTE,
    PLATFORM_CARRIER_ROUTE,
    PLATFORM_DEVICE_TYPE_ROUTE,
    PLATFORM_DEVICE_MAKE_ROUTE,
    PLATFORM_DEVICE_MODEL_ROUTE,
    PLATFORM_DEVICE_SCREEN_SIZE_ROUTE,
    PLATFORM_OPERATING_SYSTEM_ROUTE,
    PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE,
    PLATFORM_BANDWITDH,
  },
  STRATEGIES: {
    STRATEGY_ROUTE,
    STRATEGY_OPTIMIZATION_ROUTE,
  },
  TARGETING: {
    TARGETING_ROUTE,
    TARGETING_PREDICATE_ROUTE,
    TARGETING_KEY_ROUTE,
    TARGETING_LOCATION_TYPE_ROUTE,
    TARGETING_MODULE_ROUTE,
    SUMMARY_TARGETING_EXPRESSIONS_ROUTE,
  },
  TIMEZONE: {
    TIMEZONE_ROUTE,
  },
  UNIT_TIME: {
    UNIT_TIME_ROUTE,
  },
  USER: {
    USER_ROUTE,
    EMAIL_RESEND_ROUTE,
    SET_COLOR_ROUTE,
  },
  MOBILE_APP: {
    APP_BUNDLE_LIST_ROUTE,
    APP_ID_ROUTE,
  },
  NATIVE: {
    NATIVE_NATIVE_LAYOUT_ROUTE,
  },
  VIDEO: {
    VIDEO_COMPANION_REQUIRED,
    VIDEO_PLAYBACK_METHOD,
    VIDEO_START_DELAY,
    VIDEO_PLAYER_SIZE,
    VIDEO_PLACEMENT_TYPE,
  },
  SEGMENTS: {
    SEGMENTS_ROUTE,
    SEGMENTS_CATEGORY_ROUTE,
    SEGMENT_PROVIDERS_ROUTER,
    SEGMENTS_CATEGORY_LIST_ROUTE,
    SEGMENTS_THIRDPARTY_ROUTE,
    SEGMENTS_CATEGORY_THIRDPARTY_ROUTE,
    SEGMENT_TAG_ROUTE,
    SEGMENT_TREE_VIEW_ROUTE,
    SEGMENT_GENERATED_TAG_ROUTE,
    SEGMENT_THIRD_TREE,
    SEGMENT_SEARCH,
  },
  CLONE: {
    CLONE_CAMPAIGN_ROUTE,
    CLONE_LINE_ITEM_ROUTE,
    CLONE_CREATIVE_ROUTE,
  },
  CONTINENT: {
    CONTINENT_ROUTE,
  },
  LOG: {
    ACTIVITY_LOG_ADVERTISER_ROUTE,
    ACTIVITY_LOG_CAMPAIGN_ROUTE,
    ACTIVITY_LOG_LINE_ITEM_ROUTE,
    ACTIVITY_LOG_CREATIVE_ROUTE,
  },
  EXT_CAMPAIGN: {
    EXT_CAMPAIGN_ROUTE,
    EXT_CAMPAIGN_TYPES_ROUTE,
    EXT_CAMPAIGN_PROVIDERS_ROUTE,
    EXT_BUY_MODELS_ROUTE,
    EXT_CAMPAIGN_MMP_ROUTE,
    EXT_CAMPAIGN_OWNERS_ROUTE,
    EXT_CAMPAIGN_STATUS_ROUTE,
    EXT_CAMPAIGN_NOTES_ROUTE,
    EXT_CAMPAIGN_CREATIVE_ROUTE,
    EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE,
    EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE,
    EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE,
    EXT_CAMPAIGN_SEND_EMAIL_ROUTE,
    EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE,
    EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE,
    EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE,
  },
  EVENT: {
    EVENT_ROUTE,
    EVENT_ATTRIBUTIONS_ROUTE,
    EVENT_TYPES_ROUTE,
    EVENT_TAG_TYPES_ROUTE,
    EVENT_GENERATE_TAG_ROUTE,
    EVENT_ASSOCIATIONS_LINE_ROUTE,
    EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE,
  },
  NOTIFICATION: {
    NOTIFICATION_ROUTE,
    NOTIFICATION_SETTINGS_ROUTE,
    NOTIFICATION_SET_ACTIVE,
    NOTIFICATION_DISMISS,
  },
  INTERNALIZATION: {
    TRANSLATION_LANGUAGES,
  },
};
